<template>
  <v-flex>
    <v-row>
      <v-col
        cols="12"
        sm="6"
        md="6"
        v-for="(item, index) in inputCard"
        :key="index"
      >
        <v-card class="mx-auto" max-width="400">
          <v-card-title primary-title>
            <v-card-title>{{ item.title }}</v-card-title>
            <v-icon>{{ item.icon }}</v-icon>
          </v-card-title>
          <v-card-actions>
            <v-col>
              <v-select
                v-model="item.projectSelected"
                :items="projectNames"
                :rules="[(v) => !!v || 'Escolha o projeto']"
                label="Projetos"
                required
              ></v-select>
            </v-col>
          </v-card-actions>
          <v-card-actions>
            <v-col>
              <v-file-input
                dense
                show-size
                :label="item.label"
                :ref="item.ref"
                @change="onFileUpload($event, index)"
                :messages="item.isLoading ? messageLoading : ''"
                :loading="item.isLoading"
                :disabled="item.isLoading"
              ></v-file-input>
            </v-col>
            <v-btn
              :disabled="item.disabled"
              icon
              @click="sendFile(index, item.ref, item.projectSelected)"
            >
              <v-icon>send</v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-snackbar color="success" v-model="showSnackBar" bottom
      >CSV enviado com sucesso!</v-snackbar
    >
    <v-snackbar color="error" v-model="showSnackBarErrors" bottom
      >Ouve um erro durante a operação!!!</v-snackbar
    >
  </v-flex>
</template>

<script>
import { mapActions } from 'vuex';
import { saveAs } from 'file-saver';
import api from '../../../plugins/api/ApiService';

export default {
  name: 'PrivatePersonReports',

  data: () => ({
    messageLoading: 'enviando...',
    showSnackBar: false,
    showSnackBarErrors: false,
    file: null,
    projects: [],
    projectNames: [],
    inputCard: [
      {
        title: 'CSV - Enriquecidos',
        icon: 'upload_file',
        label: 'Upload CSV Enriquecido',
        ref: 'enriched',
        disabled: true,
        isLoading: false,
      },
      {
        title: 'CSV - Identificação Pendente',
        icon: 'upload_file',
        label: 'Upload CSV Identificação',
        ref: 'pending',
        disabled: true,
        isLoading: false,
        projectSelected: null
      }
    ]
  }),

  methods: {
    ...mapActions(['setTitle']),

    onFileUpload(event, index) {
      this.file = event;
      this.inputCard[index].disabled = false;
    },

    async sendFile(index, ref, projectName) {
      this.inputCard[index].isLoading = true;
      this.inputCard[index].disabled = true;
      const project = await this.projects.find((project) => {
        return project.name === projectName;
      });

      if (ref === 'enriched') {
        await this.inputEnriched(project.id);
      } else {
        await this.inputPending(project.id);
      }

      await this.$nextTick(() => {
        this.file = null;
        this.$refs[ref][0].reset();
        this.inputCard[index].isLoading = false;
        this.showSnackBar = true;
      });
    },

    async inputPending(projectId) {
      const formData = new FormData();
      await formData.append('csv', this.file);
      await formData.append('name', this.file.name);
      await formData.append('file', this.file);
      await formData.append('project_id', projectId);

      await api.privatePeopleGetCsvPending(formData)
        .then((res) => {
          const date = new Date(Date.now()).toLocaleDateString('pt-br');
          const filename = `${date}_pending_${this.file.name}`;
          const blob = new Blob([res.data], {
            type: 'text/csv;charset=utf-8'
          });

          saveAs(blob, filename);
        }).catch((err) => {
          this.showSnackBarErrors = true;
          console.error(err);
        });

      this.file = null;
    },

    async inputEnriched(projectId) {
      const formData = new FormData();
      formData.append('csv', this.file);
      formData.append('name', this.file.name);
      formData.append('file', this.file);
      await formData.append('project_id', projectId);

      return api.privatePeopleInputCsvEnriched(formData)
        .then((res) => {
          this.saveAsCsv('enriched', 'invalid', res);
          this.saveAsCsv('enriched', 'duplicated', res);
        }).catch((err) => {
          this.showSnackBarErrors = true;
          console.error(err);
        });
    },

    saveAsCsv(ref, type, res) {
      if (res.data[type] !== null) {
        const date = new Date(Date.now()).toLocaleDateString('pt-br');
        const filename = `${date}_${ref}_${type}_${this.file.name}`;
        const blob = new Blob([res.data[type]], {
          type: 'text/csv;charset=utf-8'
        });

        saveAs(blob, filename);
      }
    },

    async getProjects() {
      await api.getProjects().then((res) => {
        this.projects = res.data.filter((project) => {
          return project.project_type === 'private_person';
        });
        this.projectNames = this.projects.map((project) => project.name);
      });
    }
  },

  async created() {
    this.getProjects();
    this.setTitle({ title: 'CSV - Pessoa Física' });
  }
};
</script>
